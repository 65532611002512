.search__input{
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    height: 30px;
    padding: 10px;
    border-radius: 999px;
    width: 50vw;
    margin: 0 auto;
    /* margin-top: 40px; */
}
.MuiAutocomplete-root{
  width: 100% !important;
}

@keyframes ticker {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}


.search__input__home{
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 18;
  width: 50vw;
  margin: 0 auto;
  /* margin-top: 40px; */
}

.search__input > input {
    flex: 1;
    padding: 5px 5px;
    /* font-size: medium; */
    border: none;
}

.search__input > textarea {
  flex: 1;
  /* padding: 5px 5px; */
  /* font-size: medium; */
  border: none;
}

.search__input > input:focus {
    outline-width: 0;
}

.search__input > textarea:focus {
  outline-width: 0;
}

/* .prompt_assistance_button_group{
    margin: 1%;
}

.prompt_assistance_button_group > button {
    background-color: white;
    color: black;
    border: 1px solid;
    border-radius: 10px;
    font-size: x-small;
    text-transform: inherit;
    margin: 3px;
    min-width: 85px;
 } */
 .prompt_assistance_button_group {
    /* overflow-x: auto; */
    /* white-space: nowrap; */
    /* margin-top: 1%; */
  }
  .prompt_assistance_button_group_countainer{
    padding: 10px;
    padding-bottom: 0px;
  }

  .prompt_assistance_button_group_countainer_header{
    padding-top: 2%;
    padding-bottom: 6%;
    display: flex;
    align-items: center;
  }

 

/* .carousel .control-arrow, .carousel.carousel-slider .control-arrow{
    background-color: gray !important;
    margin-top: 10px !important;
} */
/* .carousel .control-dots{
    display: none;
} */

/* .carousel .carousel-status{
    display: none;
} */

::placeholder{
  color: black;
  opacity: 0.3;
  font-size: 15px;
}

textarea::placeholder{
  color: black;
  opacity: 0.4;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.016em;
  font-family: SF Pro Text,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
  
}
  .prompt_assistance_button_group > button {

    display: inline-block;
    /* background-color: #EFEAE9; */
    background: whitesmoke ;
    /* border: 0.1px solid gray; */
    color: #1d1d1f;
    border-radius: 12px;
    font-size: 0.74em;
    text-transform: inherit;
    margin: 0.5%;
    font-weight: 420;
    letter-spacing: 0.09em;
  }

  .prompt_assistance_button_group_write > button {

    display: inline-block;
    /* background-color: #EFEAE9; */
    background: ghostwhite ;
    /* border: 0.1px solid gray; */
    color: #1d1d1f;
    border-radius: 0px;
    font-size: 0.74em;
    text-transform: inherit;
    margin: 0.5%;
    font-weight: 420;
    letter-spacing: 0.09em;
  }

  .chatbutton {

    display: inline-block;
    /* background-color: #EFEAE9; */
    background: whitesmoke ;
    /* border: 0.1px solid gray; */
    color: #1d1d1f;
    font-weight: 420;
    border-radius: 6px;
    font-size: 0.73em;
    text-transform: inherit;
    margin: 0.5%;
    font-weight: 420;
    letter-spacing: 0.09em;
  }
  /* .prompt_assistance_button_group > button {
  background: rgb(239, 234, 233)  !important;
    color: rgb(19,52,59) !important;
    border: none;
    margin: 10px !important;
    display: flex;
    border-radius: 15px;
    text-transform: unset;
  } */

  .ask_ai > button {
    display: inline-block;

    color: black !important;
    border: none;
    border-radius: 10px;
    font-size: 0.7em;
    text-transform: inherit;
    /* min-width: 85px; */
    font-weight: 420;
    letter-spacing: 0.09em;
    
  }

  /*
  style={{background:"white", display:"flex", alignItems:"center", maxWidth:"30%", margin:"auto"}}
  */
  .askai {
    /* background: #EFEAE9; */
    background: black !important;
    color: white !important;
    opacity: 0.8;
    border: 0.1px solid lightgray;
    display: flex;
    align-items: center;
    /* max-width: 27%; */
    /* margin: auto; */
    padding-left: 3%;
    padding-right: 2%;
    border-radius: 6px;
    font-weight: bold;
    border-radius: 18px;
  }

  .askai > button {
    color:white !important;
    font-weight: 500;
  }

  /* Desktop styles */
@media (min-width: 870px) { /* Adjusted breakpoint for larger screens */
  .askai {
    background: #EFEAE9;
    display: flex;
    align-items: center;
    /* max-width: 8%; */
    /* margin: auto; */
    padding-left: 3%;
    padding-right: 2%;
    border-radius: 12px;
    /* margin-top: 10%; */
  }

}

  .group > button{
    background-color: #1d1d1f;
    /* color: white;
    border: 1px solid;
    border-radius: 10px;
    font-size: 11px;
    text-transform: inherit;
    margin: 0.5%;
    font-weight: 420;
    letter-spacing: 0.09em; */
  }

  .promptAssistanceButton > button{
    background:"#EFEAE9" !important;
  }
  .promptAssistanceButton{
    background:"#EFEAE9" !important;
    border-radius: 10px !important;
    /* font-size: 0.6em !important; */
    margin: 5px !important;
    text-transform: inherit;
    font-weight: 500 !important;
    padding: 9px !important;
  }

  .group > button::after{
    background-color: #1d1d1f;
    /* color: white;
    border: 1px solid;
    border-radius: 10px;
    font-size: 11px;
    text-transform: inherit;
    margin: 0.5%;
    font-weight: 420;
    letter-spacing: 0.09em; */
  }

  .prompt_assistance_button_group{
    text-align: center;
    /* padding-left: 2%; */
  }
  
  /* .slider-frame{
    overflow-x: visible !important;
  } */
  .slider-control-bottomcenter{
    display: none;
  }
  /* .control-arrow control-next::before{
    background: gray !important;
  }

  .control-arrow control-next{
    background: black !important;
    opacity: none !important;
  }
  .control-arrow control-prev{
    background: black !important;
    opacity: none !important;
  }

  .control-arrow control-prev control-disabled::active{
    background: red !important;
  }

  .control-arrow control-next control-disabled::active{
    background: red !important;
  }
  
  .prompt_assistance_button_group > button:active{
    background: white;
  } */

  .prompt_assistance_button_group > button:hover {
    /* display: inline-block;
    background-color: white;
    color: black;
    border: 1px solid;
    border-radius: 10px;
    font-size: x-small;
    text-transform: inherit;
    margin: 1%;
    min-width: 85px; */
    /* display: inline-block; */
    background-color: whitesmoke;
    /* color: white;
    border: 1px solid;
    border-radius: 10px;
    font-size: 11px;
    text-transform: inherit;
    margin: 0.2%;
    min-width: 85px;
    font-weight: 500; */
  }

  /* @media only screen and (min-width: 90px) {
  .search__input{
    width: "50%" !important;
    margin:"auto" !important;
  }
}  */

.search__input > input {
  font-size: medium !important;
}
.search__input > textarea {
  font-size: 15px !important;
  font-family: "Roboto", sans-serif; 
  resize: none;
}

@media only screen and (min-width: 1000px) {
  .search{
    width: 50vw;
    margin: auto;
  }

  .prompt_assistance_button_group_countainer_header{
    padding-top: 1%;
    padding-bottom: 1%;
    display: flex;
    align-items: center;
  }
}   

@media only screen and (max-width: 767px) {
    .search__input > input:focus {
        outline-width: 0;
    }
    input:focus {
        
        outline: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-appearance: none;
        box-shadow: none;
      }
  }

  textarea:focus {
        
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: none;
    box-shadow: none;
  }
  
  .search__input > textarea:focus {
    outline-width: 0;
}
textarea:focus {
    
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: none;
    box-shadow: none;
  }


textarea:focus {
    
outline: none;
-webkit-tap-highlight-color: transparent;
-webkit-appearance: none;
box-shadow: none;
}

  


/* .search__buttons{
    margin-top: 30px;
    justify-content: center;
    display: flex;
}
.search__buttons button{
    margin: 5px;
    padding: 7px 15px;
    background-color: #f8f8f8;
    border: 1px solid white;
    text-transform: inherit;
} 

.search__buttons button:hover{
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    background-color: #f8f8f8;
} */

.search__buttons__hide{
    display: none;;
}